<template>
  <div class="p-grid shortlisted-matches">
    <div class="p-col-12 p-p-0">
      <div
        :class="`${layout === 'list' ? 'p-mb-3 p-mx-0' : ''} ${
          loading ? 'p-mb-5' : ''
        } p-d-flex p-jc-end p-ai-center mobv`"
      >
        <Button
          v-show="!loading && profiles.length > 0"
          icon="pi pi-bars"
          :class="layout === 'list' ? 'p-mx-1' : 'p-button-outlined'"
          @click="(selectedLayout = 'list'), resizing()"
        />
        <Button
          v-show="!loading && profiles.length > 0"
          icon="pi pi-th-large"
          :class="layout === 'grid' ? 'p-mx-1' : 'p-button-outlined'"
          @click="(selectedLayout = 'grid'), resizing()"
        />
      </div>
      <div
        v-if="profiles.length !== 0 || loading"
        :class="`${loading ? (layout === 'list' ? 'p-pt-4' : '') : 'p-pt-0'}`"
      >
        <list-view
          v-if="layout === 'list'"
          :profiles="profiles"
          :loading="loading"
          :profile-number="profileNumber"
          :load-profiles="loadProfiles"
          @updateShortlist="shortlistProfile"
          @sendinterest="sendinterest"
        />
        <grid-view
          v-else
          :profiles="profiles"
          :loading="loading"
          :profile-number="profileNumber"
          :load-profiles="loadProfiles"
          @updateShortlist="shortlistProfile"
          @sendinterest="sendinterest"
        />
      </div>
      <div v-else>
        <empty-mails
          :empty-text="'You haven\'t shortlisted anyone untill now'"
          :img="authUser.gender === 'male' ? '/bride.png' : '/groom.png'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GridView from '@/components/views/GridView.vue';
import ListView from '@/components/views/ListView.vue';
import EmptyMails from '@/components/EmptyMails.vue';
import { mapGetters } from 'vuex';
import { mailboxMixin } from '@/mixins/mailboxMixin';
export default {
  components: {
    GridView,
    ListView,
    EmptyMails,
  },
  mixins: [mailboxMixin],
  computed: {
    ...mapGetters(['authUser']),
    reqUrl() {
      return `/users/${this.authUser.slug}/shortlisted_profiles`;
    },
  },
  methods: {
    shortlistProfile(id, shortlisted) {
      let that = this;
      this.$http.patch(`/users/${that.authUser.slug}/add_or_remove_shortlist`, {
        shortlisted: shortlisted,
        user_id: id,
      });
      this.profiles.splice(
        this.profiles.indexOf(
          this.profiles.find((profile) => profile.attributes.id === id)
        ),
        1
      );
    },
    sendinterest(to_user_id) {
      let that = this;
      this.$http.post('/interests', {
        interest: { from_user_id: that.authUser.id, to_user_id: to_user_id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.shortlisted-matches {
  margin-top: -12px;
}
.mobv {
  margin-right: 7px;
}
</style>
